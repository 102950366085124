exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-about-js": () => import("./../../../src/pages/company/about.js" /* webpackChunkName: "component---src-pages-company-about-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-company-mission-js": () => import("./../../../src/pages/company/mission.js" /* webpackChunkName: "component---src-pages-company-mission-js" */),
  "component---src-pages-company-representative-js": () => import("./../../../src/pages/company/representative.js" /* webpackChunkName: "component---src-pages-company-representative-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-hr-js": () => import("./../../../src/pages/service/hr.js" /* webpackChunkName: "component---src-pages-service-hr-js" */),
  "component---src-pages-service-index-js": () => import("./../../../src/pages/service/index.js" /* webpackChunkName: "component---src-pages-service-index-js" */),
  "component---src-pages-service-sr-js": () => import("./../../../src/pages/service/sr.js" /* webpackChunkName: "component---src-pages-service-sr-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-blogs-template-js": () => import("./../../../src/templates/blogs-template.js" /* webpackChunkName: "component---src-templates-blogs-template-js" */),
  "component---src-templates-informations-list-js": () => import("./../../../src/templates/informations-list.js" /* webpackChunkName: "component---src-templates-informations-list-js" */),
  "component---src-templates-journal-list-js": () => import("./../../../src/templates/journal-list.js" /* webpackChunkName: "component---src-templates-journal-list-js" */),
  "component---src-templates-tips-list-js": () => import("./../../../src/templates/tips-list.js" /* webpackChunkName: "component---src-templates-tips-list-js" */)
}

